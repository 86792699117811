import React, { Component } from "react"
import get from "lodash/get"
import Template from "../components/layout"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ImageGallery from '../components/image-gallery';
import "../components/trip.css"


class TripTemplate extends Component {
  showPicture = () => {
    console.log("SHOWING PICTURE")
  }

  render() {
    const trip = get(this, "props.data.contentfulTrip")

    return (
      <Template>
        <Helmet title={`${trip.title}`} />
        <div className="inner-blog-post">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h1 className="section-headline"> {trip.title} </h1>
                <ImageGallery
                  images={trip.carousel}
                  itemsPerRow={[2, 3]}
                  showPicture={this.showPicture}
                />
              </div>
            </div>
          </div>
        </div>
      </Template>
    )
  }
}

export default TripTemplate

export const pageQuery = graphql`
  query tripQuery($slug: String) {
    contentfulTrip(slug: { eq: $slug }) {
      title
      image {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
        fixed {
          base64
          aspectRatio
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
      }
      carousel {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
        fixed {
          base64
          aspectRatio
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
      }
    }
  }
`
