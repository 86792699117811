import React, { useState, useCallback, setState } from 'react';
import Img from 'gatsby-image';
import { chunk, sum } from 'lodash';
import { Box } from 'rebass';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const ImageGallery = ({ images, itemsPerRow: itemsPerRowByBreakpoints }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  };

  const aspectRatios = images.map(image => image.fluid.aspectRatio);
  // For each breakpoint, calculate the aspect ratio sum of each row's images
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      // Split images into groups of the given size
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        // Sum aspect ratios of images in the given row
        sum(rowAspectRatios),
      ),
  );

  const photoList =
    images.map(element => (
      {
        src: element.fluid.src,
        height: element.fixed.height,
        width: element.fixed.width,
      }
    ))

  return (
    <div>
      <Gallery photos={photoList} onClick={openLightbox} />
      <ModalGateway>
         {viewerIsOpen ? (
           <Modal onClose={closeLightbox}>
             <Carousel
               currentIndex={currentImage}
               views={images.map(x => ({
                 ...x.fluid,
                 srcset: x.fluid.srcSet,
                 caption: x.fluid.title
               }))}
             />
           </Modal>
         ) : null}
       </ModalGateway>
    </div>
  );
};

export default ImageGallery;
